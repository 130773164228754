import iconCustomer from "assets/images/contact-us/customer_support2x.png";
import iconHome from "assets/images/contact-us/home_financing_servicing@2x.png";
import iconTools from "assets/images/contact-us/sustainable_home_solutions2x.png";

export const items = [
  {
    icon: iconCustomer,
    title: "CUSTOMER SUPPORT",
    phone: "1.844.LOANPAL",
    schedule: [
      { day: "Monday - Friday 9am-6pm PST" },
      { day: "Saturday - 9am-1pm PST" },
    ],
    email: "CustomerCare@loanpal.com",
  },
  {
    icon: iconHome,
    title: "HOME FINANCING SERVICING",
    phone: "1.888.288.1791",
    schedule: [{ day: "Monday - Friday 8am-5pm PST" }],
  },
  {
    icon: iconTools,

    title: "SUSTAINABLE HOME SOLUTIONS",
    phone: "1.844.910.0111",
    tollFree: true,
    schedule: [
      { day: "Monday - Thursday - 7am-9pm PST" },
      { day: "Friday - 7am-8pm PST" },
      { day: "Saturday - 8am-7pm PST" },
      ,
      { day: "Sunday - 9am-6pm PST" },
    ],
    email: "SolarInfo@loanpal.com",
  },
];

export default { items };
