import React from "react";

import Main from "components/layout/Main";
import HeaderSkew from "components/HeaderSkew";
import Block from "components/Block";
import ContentGrid from "components/ContentGrid";
import GridCards from "components/GridCards";
import CardBenefit from "components/CardBenefit";

import backgroundOverlay from "assets/images/header_skew/contact_us_header_image.jpg";

import ScrollToTop from "components/ScrollToTop";

import { items } from "data/page-contact-us";

export default function ContactUsPage() {
  const title = "Contact Us";
  const url = "contact-us";

  return (
    <Main page={url} pagetitle={title}>
      <HeaderSkew
        title={title}
        subtitle=""
        url={url}
        background={backgroundOverlay}
        backgroundColor="small-contact"
      />
      <Block layer="1" name="" guides={1} appearance="grid-top-gray" />
      <div className="pointer-parent">
        <Block layer="1" guides={1} appearance="grid-contact">
          <ContentGrid appearance="slim-x">
            <GridCards modifier={["3"]}>
              {items.map((item, index) => (
                <CardBenefit {...item} modifier={["contact-us"]} key={index} />
              ))}
            </GridCards>
          </ContentGrid>
          <ScrollToTop modifier={["slim-top"]} guides={0} />
        </Block>
      </div>
    </Main>
  );
}
